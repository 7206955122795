import React from 'react';
import { Box, Container, CssBaseline, useMediaQuery, useTheme, Grid } from '@mui/material';

const Premios = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        {!isMobile && (
          <Box sx={{ width: '25%' }}>
            <Box sx={{ width: '100%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
              <img src='/img/jxjbannercontexto.jpg' alt="Juga por jugar" style={{ maxWidth: '100%', height: 'auto', position: 'absolute', top: '0%' }} />
         
            </Box>

            <Box sx={{ height: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container spacing={2} sx={{ height: '100vh' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src='/img/jugaporjugar.png' alt="Juga por jugar" style={{ maxWidth: '100%', maxHeight: '80%' }} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
        <Box sx={{ width: isMobile ? '100%' : '75%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ bgcolor: '#d7412a', p: 1 }}>
            <h1 className='titconcurso'>¡PREMIOS!</h1>
          </Box>
          <Box sx={{ display: 'flex', p: 1, justifyContent: 'center', alignItems: 'center' }}>

            <img src={isMobile ? '/img/premiosm.png' : '/img/premiosw.png'} alt="Premios" style={{ Width: '100%', Height: '100%' }} />

          </Box>
          <Box sx={{ bgcolor: 'grey.100', p: 2 }}>
            <h1 className='titulo1'>
              Por dudas y consultas envíanos un mail a:
              <br />  
                <a href="mailto:juegoresponsable@inprojuy.gob.ar">juegoresponsable@inprojuy.gob.ar</a>
        
              <Box sx={{ textAlign: 'center', marginTop: '5px' }}>
                <img src='/img/cine.png' alt="Jugá por jugar" style={{ maxWidth: '100%' }} />
              </Box>
            </h1>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Premios;
